@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamMedium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/GothamBlack.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'GothamCond';
    src: url('../fonts/GothamCond-Medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GothamCond';
    src: url('../fonts/GothamCond-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'GothamCond';
    src: url('../fonts/GothamCond-Book.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'GothamCond';
    src: url('../fonts/GothamCond-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'GothamCond';
    src: url('../fonts/GothamCond-Black.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url('../fonts/Gotham-Medium.otf') format('otf');
    font-style: normal;
}